import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button, Form, Input, CardTitle, FormGroup, Alert } from "reactstrap";
import { useNavigate } from 'react-router-dom';

import userService from "services/userService";
import { useAuth } from 'contexts/AuthContext';


function Login() {

  const { login, error, isLoggedIn } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if (user?.id && token) {
      navigate('/admin/dashboard');
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const email = e.target[0].value;
    const password = e.target[1].value;

    const resp = await login(email, password);
    if (resp?.token) {
      //navigate('/admin/dashboard');
    }
  }

  return (
    <Row className="content justify-content-center" style={{ backgroundColor: "#f3f3f3", height: window.innerHeight - 15, justifyContent: "center" }}>
      <Col md="6" xm="12" sm="12" style={{ paddingTop: "10%", alignItems: "center" }}>

        <Card className="card-user m-3" style={{ maxWidth: "500px", }}>
          <img src={require("assets/img/logo.jpeg")} alt="..." style={{ width: "300px", alignSelf: "center", marginTop: "20px" }} />

          <Col className="">
            {error?.status && error?.status != 201 && <Alert color="warning">
              {error?.message}
            </Alert>}
            {error?.status == 201 && <Alert color="success">
              Login feito com sucesso!
            </Alert>}
          </Col>
          <br />
          <CardHeader>
            <CardTitle className="text-center" tag="h5">Entrar</CardTitle>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit}>
              <Row>
                <Col className="m-1" md="12">
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">
                      CPF
                    </label>
                    <Input
                      placeholder="digitar CPF"
                      type="text" name="email"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="m-1" md="12">
                  <FormGroup>
                    <label>Senha</label>
                    <Input
                      placeholder="senha"
                      type="password"
                      name="password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <a
                className="ml-1"
                color="primary"
                type="link"
                href={"/recuperar-senha"}
              >
                Recuperar senha
              </a>
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Entrar
                  </Button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <br />
        <a className="ml-3" onClick={() => navigate('/cadastrar')}> Cadastrar-se como Sócio </a>
      </Col>
    </Row>
  );
}

export default Login;
