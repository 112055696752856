import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import SunEditor from "suneditor-react";
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import { MultiSelect } from 'primereact/multiselect';

import 'suneditor/dist/css/suneditor.min.css';
import FullCalendar from "@fullcalendar/react";
import ApiCalendar from 'react-google-calendar-api';
import api from "services/api";

const config = {
  "clientId": "211709124212-ecjssc8pumqvvn5a35816vuuf3aga2n3.apps.googleusercontent.com",
  "apiKey": "AIzaSyAWY2lqCF_sKg3Tpz4XYzAzt9g6MuNrcls",
  "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}
/*const config = {
  "clientId": "32608703005-6vufuo1je94snr8ccahf175h4vc17udu.apps.googleusercontent.com",
  "apiKey": "AIzaSyCeiu3IuMxStlx3UwkXCN7thSmAoG5MuuE",
  "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}*/

/*const config = {
  "clientId": "1076445512781-1gnfdr4p19tfhj97o9jd8grrk2gdnk9v.apps.googleusercontent.com",
  "apiKey": "AIzaSyCQW0U8rFGPKSF-Le3Qo9ZLU9vsDT6NBfs",
  "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}*/

const defaultCalendarId = "c_569bdff9d499359e24fb27a3fa74e28cbc6fa85aa81856588c0e88cd27f435e3@group.calendar.google.com";
const defaultCalendarId1 = "21b750d8fb3a14581155d1177ae25d450c06d6ce19a05e64791863f91c3daa0e@group.calendar.google.com";

const defaultCalendarId2 = defaultCalendarId;
const table = "agendamentos";
function AgendamentoScreen({ nameRoute }) {

  const apiCalendar = new ApiCalendar(config)


  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [items, setUsers] = useState([]);
  const [socios, setSocios] = useState([]);
  const [itemsSelect, setItemsSelect] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const [events, setEvents] = useState([]);
  const notificationAlert = React.useRef();

  const [selectedCities, setSelectedCities] = useState(null);
 
  useEffect(() => {
    listItems();

    setTimeout(async () => {
      apiCalendar.onLoad(
        async () => {
          //const resp = await apiCalendar.handleAuthClick()
          //let events = await apiCalendar.listEvents(10, defaultCalendarId2) 
          let events = await apiCalendar.listEvents(10, defaultCalendarId2)
          var events_ = events?.result?.items.map(
            item => {
              return {
                title: item?.summary ? item?.summary : 'titulo',
                start: item?.start?.date || item?.start?.dateTime,
                end: item?.end?.date || item?.end?.dateTime + "Z",
                url: item?.hangoutLink,
              }
            })
          setEvents(events_)
        }
      )
    }, 2000)

  }, []);

  const listItems = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);

    var items_ = await defaultService.list(table);
    items_?.[0]?.id && setUsers(items_);

    var items_ = await defaultService.listSelect("socios");
    items_?.[0]?.id && setSocios(items_);

    var aux = [];
    items_?.map((item) => {
     aux.push({
      name: item?.nome,
      code: item?.email,
     });
    })

    setItemsSelect(aux);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const participantes = selectedCities?.map((item) => {
      return {
        email: item?.code
      }
    })

    var start = new Date(e.target['inicio'].value).setTime(new Date(e.target['inicio'].value).getTime());
    start = new Date(start).toJSON();

    var end = new Date(e.target['fim'].value).setTime(new Date(e.target['fim'].value).getTime());
    end = new Date(end).toJSON();
    //console.log(newDate.toJSON())

    //return
    let resp;
    resp = await apiCalendar
      .createEventWithVideoConference({
        summary: e.target['titulo'].value,
        description: e.target['descricao'].value,
        start: {
          "dateTime": start,
          "timeZone": "America/Sao_Paulo"
        },
        end: {
          "dateTime": end,
          "timeZone": "America/Sao_Paulo"
        },
        attendees: participantes,
      }, defaultCalendarId2)

    if (!resp) {
      apiCalendar.onLoad(async () => {


        await apiCalendar.handleAuthClick()
          .then(async () => {


            const resp = await apiCalendar
              .createEventWithVideoConference({
                summary: e.target['titulo'].value,
                description: e.target['descricao'].value,
                start: {
                  "dateTime": start,
                  "timeZone": "America/Sao_Paulo"
                },
                end: {
                  "dateTime": end,
                  "timeZone": "America/Sao_Paulo"
                },
                attendees: participantes,
              }, defaultCalendarId2)

            let events = await apiCalendar.listEvents(10, defaultCalendarId2)
            var events_ = events?.result?.items.map(
              item => {
                return {
                  title: item?.summary ? item?.summary : 'titulo',
                  start: item?.start?.date || item?.start?.dateTime,
                  end: item?.end?.date || item?.end?.dateTime + "Z",
                  url: item?.hangoutLink,
                }
              })
            setEvents(events_)
            setIsOpen(false);
            notify("tr", 2, 'Cadastro feito com sucesso!')
          })
      })

    } else {

      let events = await apiCalendar.listEvents(10, defaultCalendarId2)
      var events_ = events?.result?.items.map(
        item => {
          return {
            title: item?.summary ? item?.summary : 'titulo',
            start: item?.start?.date || item?.start?.dateTime,
            end: item?.end?.date || item?.end?.dateTime + "Z",
            url: item?.hangoutLink,
          }
        })
      setEvents(events_)
      setIsOpen(false);
      notify("tr", 2, 'Cadastro feito com sucesso!')
    }



  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    const resp = await defaultService.delete(currentItem.id, table);

    resp?.status == 200 ?
      notify("tr", 2, "Deletado com sucesso!")
      : notify("tr", 3, 'Erro ao deletar!');

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">

        <NotificationAlert ref={notificationAlert} />
        <ModalConfirm isOpen={modalConfirm} toggle={deleteItem} />
        <Row>
          <Col md="12">
            <Modal
              size="xl"
              isOpen={isOpen}
              toggle={() => { setIsOpen(false); setCurrentItem({}) }} >
              <h6 className="text-right pr-5 mt-3" style={{ cursor: "pointer" }} onClick={() => { setIsOpen(false); setCurrentItem({}); }}>Fechar</h6>
              <Card className="card-user m-3" >
                <CardHeader>
                  <CardTitle tag="h5">Agendamento</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={onSubmit} datatype="multipart/form-data">
                    <Input type="hidden" name="id" defaultValue={currentItem?.id} />

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Título</label>
                          <Input
                            defaultValue={currentItem?.nome}
                            type="text"
                            name="titulo"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <label>Descrição</label>
                          <Input
                            defaultValue={currentItem?.nome}
                            type="text"
                            name="descricao"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <MultiSelect
                            filter
                            options={itemsSelect}
                            optionLabel="name"
                            style={{ width: "100%" }}
                            value={selectedCities}
                            placeholder="Selecionar participantes"
                            onChange={(e) => setSelectedCities(e.value)} />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Início</label>
                          <Input
                            defaultValue={currentItem?.email}
                            type="datetime-local"
                            name="inicio"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Fim</label>
                          <Input
                            defaultValue={currentItem?.cpf}
                            placeholder=""
                            type="datetime-local"
                            name="fim"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          {currentItem.id ? "Atualizar" : "Criar"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Modal>
          </Col>
                      
          <Col md="12" style={{ display: localUser?.tipo_acesso === 'Sindicalizado' ? 'none' : 'flex' }}>
            <Button
              className="btn-round"
              color="primary"
              onClick={() => { setIsOpen(true); setCurrentItem({}) }}
            >
              Criar Novo
            </Button>
          </Col>

          <Col md="12">
            <Card style={{ display: 'none' /*localUser?.tipo_acesso === 'Sindicalizado' ? 'none' : 'flex'*/ }}>
              <CardHeader>
                <CardTitle tag="h4">{nameRoute}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Titulo</th>
                      <th>Data</th>
                      <th>Status</th>
                      <th className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.map((item) => (
                      <tr id={item.id + 'item1'}>
                        <td>{item.nome}</td>
                        <td>{new Date(item.data_agenda).toLocaleDateString()}</td>
                        <td>{item.status_agenda}</td>
                        <td className="text-right">
                          <Button
                            color="primary"
                            round
                            className="mr-1"
                            placeholder="Editar"
                            onClick={() => { setCurrentItem(item); setIsOpen(true); }}>
                            <i className="fa fa-pen"></i>
                          </Button>
                          <Button
                            color="danger"
                            round
                            className="mr-1"
                            onClick={() => { setModalConfirm(true); setCurrentItem(item); }}>
                            <i className="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <FullCalendar
              plugins={[dayGridPlugin]}
              locale={"pt-br"}
              initialView="dayGridMonth"
              events={events}
            /*events={[
              { title: 'Reunião', date: '2024-01-18', interactive: true, extendedProps: { description: 'sdf',  } },
              { title: 'Reunião 2', date: new Date('2024-01-19T13:30:00Z').toUTCString() }
            ]}*/
            />

          </Col>
        </Row>
      </div>
    </>
  );
}

export default AgendamentoScreen;
